import { Controller } from 'stimulus'

import Rails from '@rails/ujs'
import axios from 'axios'

export default class extends Controller {
  static targets = ['cityInput', 'houseNumberInput', 'postalCodeInput', 'streetInput']

  connect() {
    axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()
  }

  fetch() {
    if (this.isValidPostcode() && this.isValidHouseNumber()) {
      this.fetchPostalCodeData()
    }
  }

  fetchPostalCodeData() {
    axios.get(
      this.data.get('url'),
      { params: { number: this.houseNumberInputTarget.value, postcode: this.postalCodeInputTarget.value } }
    ).then((response) => {
      const city = response.data.city
      const street = response.data.street

      if (city && street) {
        this.cityInputTarget.value = city
        this.streetInputTarget.value = street
      } else {
        this.cityInputTarget.value = ''
        this.streetInputTarget.value = ''
      }
    })
  }

  isValidHouseNumber() {
    return this.houseNumberInputTarget.value.length > 0;
  }

  isValidPostcode() {
    return this.postalCodeInputTarget.value.length > 5 && this.postalCodeInputTarget.value.length < 8;
  }
}
