import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']
  static values = { name: String }

  toggle() {
    this.elementTarget.classList.toggle(this.nameValue)
  }
}
