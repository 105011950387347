import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['categories']

    hideCategories() {
        this.categoriesTarget.classList.remove('open')
    }

    showCategories(event) {
        this.categoriesTarget.classList.add('open')
    }
}
