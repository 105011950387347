import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['arrowLeft', 'arrowRight', 'slides']

  connect() {
    this.slidesCount = this.slidesTarget.children.length
    this.index = 0

    this.disableArrows()
  }

  disableArrows() {
    this.arrowLeftTarget.classList.toggle('disabled', this.index == -(this.slidesCount - 1))
    this.arrowRightTarget.classList.toggle('disabled', this.index == 0)
  }

  slide() {
    const position = event.currentTarget.dataset.position
    const slideElement = this.slidesTarget.children[0]
    const slideMargin = parseInt(window.getComputedStyle(slideElement).marginRight)
    const slideWidth = slideElement.offsetWidth + slideMargin

    if (position == 'left' && this.index > -(this.slidesCount - 1)) {
      this.index--;
    }

    if (position == 'right' && this.index < 0) {
      this.index++;
    }

    this.slidesTarget.style.left = (slideWidth * this.index) + 'px'
    this.disableArrows()
  }
}
