import Timer from 'easytimer.js'
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['timer']

    connect() {
        if (this.countdownRequired()) {
            this.countdown()
        }
    }

    countdown() {
        const timer = new Timer()

        timer.start({
            countdown: true,
            startValues: { seconds: parseInt(this.data.get('time')) }
        })

        timer.addEventListener('secondsUpdated', () => {
            this.timerTarget.innerHTML = timer.getTimeValues().toString()
        })
    }

    countdownRequired() {
        const daySize = 86400

        return this.hasTimerTarget && parseInt(this.data.get('time')) < daySize
    }
}
