import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'trigger']

  connect() {
    this.hideTabs()
    this.showFirstTab()
  }

  hideTabs() {
    for (let trigger of this.triggerTargets) { trigger.classList.remove('is-active') }
    for (let tab of this.tabTargets) { tab.classList.remove('is-active') }
  }

  showFirstTab() {
    this.triggerTargets[0].click()
  }

  toggle(event) {
    const tabName = event.currentTarget.dataset.tabName
    const tab = this.tabTargets.find(element => element.dataset.tabName == tabName)

    this.hideTabs()
    event.currentTarget.classList.add('is-active')
    tab.classList.add('is-active')
  }
}
