import Rails from '@rails/ujs'
import axios from 'axios'
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['amountInput', 'handlingAmount', 'totalAmount', 'vatAmount']

    connect() {
        this.calculateTotal()
    }

    calculateTotal() {
        axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()

        axios.post(
            this.data.get('calculateUrl'),
            { bid: { amount: this.amountInputTarget.value } }
        ).then((response) => {
            this.handlingAmountTarget.innerText = response.data['handling']
            this.vatAmountTarget.innerText = response.data['vat']

            this.totalAmountTargets.forEach(target => target.innerText = response.data['total'])
        })
    }
}
