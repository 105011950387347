import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal']

  show() {
    this.modalTarget.classList.add('is-active')
  }

  hide() {
    this.modalTarget.classList.remove('is-active')
  }
}
