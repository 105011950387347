import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['rowTemplate', 'formRows']

    addRow(event) {
        event.preventDefault()

        const newRowContent = this.rowTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

        this.formRowsTarget.insertAdjacentHTML('beforeend', newRowContent)
    }
}
