import { Controller } from 'stimulus'

export default class extends Controller {
    toggle() {
        this.closeOthers()
        this.element.classList.toggle('is-active')
    }

    closeOthers() {
        const dropdowns = document.querySelectorAll(`[data-controller="dropdown"]`)

        dropdowns.forEach(dropdown => {
            if (dropdown != this.element) {
                dropdown.classList.remove('is-active')
            }
        })
    }
}
