import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['picker']
  static values = { disableTime: Boolean }

  connect() {
    flatpickr(
      this.pickerTarget,
      { enableTime: !this.disableTimeValue }
    )
  }
}
