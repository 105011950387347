import { Controller } from 'stimulus'

import Rails from '@rails/ujs'
import axios from 'axios'

export default class extends Controller {
  connect() {
    axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()
  }

  sort() {
    const order = event.currentTarget.value

    let url = new URL(window.location.href)
    url.searchParams.set('order', order)

    window.location.href = url.toString()
  }
}
