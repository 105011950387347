import { Controller } from 'stimulus'

import GLightbox from 'glightbox'
import Swiper, { Navigation } from 'swiper'

export default class extends Controller {
    static targets = ['gallery', 'thumbnails']

    connect() {
        this.swiperInstance = null

        this.initializeLightbox()
        this.initializeSwiper()
    }

    initializeLightbox() {
        GLightbox({ selector: 'data-lightbox' })
    }

    initializeSwiper() {
        Swiper.use([Navigation])

        this.swiperInstance = new Swiper(
            this.galleryTarget,
            {
                allowTouchMove: false,
                loop: true,
                navigation: {
                    prevEl: '.lot-image__arrow--prev',
                    nextEl: '.lot-image__arrow--next'
                }
            }
        )

        this.swiperInstance.on('slideChange', (slider) => {
            const thumbnail = this.thumbnailsTarget.querySelector('[data-slide-index="' + (slider.realIndex + 1) + '"')
            this.applyThumbnailClass(thumbnail)
        })
    }

    applyThumbnailClass(thumbnail) {
        Array.from(this.thumbnailsTarget.children).forEach(element => element.classList.remove('active'))
        thumbnail.classList.add('active')
    }

    toggleThumbnail(event) {
        event.preventDefault()

        const thumbnail = event.currentTarget
        const index = parseInt(thumbnail.dataset.slideIndex)

        this.swiperInstance.slideTo(index)
        this.applyThumbnailClass(thumbnail)
    }
}
