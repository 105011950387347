import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['content']

    static values = {
        interval: Number,
        url: String
    }

    connect() {
        this.refresh()
    }

    async refresh() {
        await fetch(this.urlValue)
            .then(response => response.text())
            .then(html => this.contentTarget.innerHTML = html)

        setTimeout(() => this.refresh(), this.intervalValue)
    }
}
