import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content']

  connect() {
    this.contract()
  }

  contract() {
    this.contentTarget.style.display = 'none'
  }

  toggle(event) {
    const display = this.contentTarget.style.display

    display == 'block' ? this.contentTarget.style.display = 'none' : this.contentTarget.style.display = 'block'
    event.currentTarget.classList.toggle('toggled')
  }
}
